<template>
  <div class="set-commission" ref="setCommissionRef">
    <artmisads-modal
      v-model:open="props.showSetCommission"
      :title="`Set Public Commission`"
      :width="600"
      :onCancel="onCancel"
      :getContainer="() => setCommissionRef"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="set-commission-wrap">
        <p class="first-p">The public commission is displayed to all publishers/creators when the product is active on the platform. Artemis Ads Fees are displayed to you and Artemis Ads only.</p>
        <div class="content">
          <div class="commission">
            <div class="commission-input">
              <span>Affiliate Commission</span>
              <a-input-number
                v-model:value="commission"
                :min="0"
                :max="100"
                :parser="value => value.replace('%', '')"
                ref="commissionMinRef"
                size="large"
              />
              <span class="haifenhao">%</span>
              <span v-if="showDefalutText" class="defalut-text" @click="clickDefalutText">Please enter...</span>
            </div>
            <span>+</span>
            <div class="fees">
              <span>ArtemisAds Fees</span>
              <artmisads-button type="primary" disabled>{{fees}}%</artmisads-button>
            </div>
            <span>=</span>
            <div class="total">
              <span>Total</span>
              <artmisads-button type="primary" disabled>{{((Number(fees) + Number(commission))).toFixed(1)}}%</artmisads-button>
            </div>
          </div>
          <p class="second-p">You could receive an average of 10% of the sales back from Amazon as Brand Referral Bonus.</p>
          <p  class="third-p">The percentages displayed above will be applied to sales data that Artemis Ads receives from Amazon Attribution. Please note that sales reported by Amazon Attribution excludes Discounts (Instant Discount), Prime Day Deals, Prime Exclusive Deals, and Limited Time Deals, while DOES NOT exclude discounts from Promo Codes, Chippable Coupons, Subscribe & Save, or Lightning Deals.</p>
        </div>
      </div>
      <template #footer>
        <artmisads-button @click="saveCommission" type="primary">Save Commission</artmisads-button>
      </template>
    </artmisads-modal>
  </div>
</template>

<script setup>
  import { message } from 'ant-design-vue';
  import { ref, onBeforeMount, computed, watch } from 'vue';
  import { productsApi } from '../../../../server/index'

  const showDefalutText = ref(true);
  const commissionMinRef = ref(null);
  const props = defineProps(['showSetCommission', 'setShowSetCommission', 'getProductDetail', 'deatilData', 'productId']);
  const defaultCommission = ref({
    defaultCommissionRate: 1,
    platformFeeFactor : 1,
    platformMaxFeeRate: 1
  }) 
  const commission = ref('')
  const setCommissionRef = ref(null);

  const fees = computed(() => {
    let value = '';
    if (commission.value) {
      value = commission.value * defaultCommission.value.platformFeeFactor / 100;
    } else {
      value = defaultCommission.value.defaultCommissionRate * defaultCommission.value.platformFeeFactor / 100;
    }
    if (value >=  defaultCommission.value.platformMaxFeeRate) {
      value = defaultCommission.value.platformMaxFeeRate;
    }
    return value.toFixed(1)
  })
  watch(() => commission.value, (newValue) => {
    showDefalutText.value = newValue === null;
  })

  const onCancel = () => {
   props.setShowSetCommission(false);
   commission.value = '';
  }

  const saveCommission = async () => {
    const params = {
      commissionRate: commission.value,
      ids: [props.productId],
      platformFeeRate: fees.value,
    }
    const res = await productsApi.setCommission(params);
    const { success, error } = res;
    if (success) {
      message.success('Success');
      props.getProductDetail(props.productId);
      onCancel();
    }
  }

  const clickDefalutText = () => {
    showDefalutText.value = false;
    commissionMinRef.value.focus();
  }

  const getRate = async () => {
    const res = await productsApi.getCommissionConfig();
    const { success, data, error } = res;
    if (success) {
      defaultCommission.value = data;
    }
  }

  watch(() => props.showSetCommission, newValue => {
    if (newValue) {
      getRate();
    }
    commission.value = props.deatilData.commission
  })

</script>

<style lang="less" scoped>
  .set-commission {
    .set-commission-wrap {
      p {
        font-family: Sora-Regular;
      }
      .first-p {
        padding: 8px 0  24px;
        color: var(--dark-3);
        font-weight: 400;
        line-height: 22px;
      }
      .third-p {
        margin-top: 28px;
        background: var(--big-bg-2);
        border-radius: 8px;
        padding: 8px 12px;
        font-size: var(--font-size-min);
        color: var(--dark-3);
        letter-spacing: 0.2px;
        line-height: 18px;
      }
      .second-p {
        font-size: var(--font-size-min);
        align-self: end;
        padding: 8px 12px;
        margin-top: 15px;
        width: 286px;
        background: var(--color-white);
        border: 1px solid var(--border-4);
        box-shadow: var(--section-box-shadow-2);
        border-radius: 8px;
        color: var(--dark-3);
        letter-spacing: 0.2px;
        line-height: 16px;
        position: relative;
        font-style: italic;
        &::after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: -10px;
          right : 115px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid var(--color-white);
          
        }
        &::before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: -11px;
          right : 115px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid var(--border-4);
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      // padding: 0 24px 24px 24px;
      .commission {
        display: flex;
        > span {
          align-self: center;
          margin: 20px 10px 0 10px;
          font-family: Sora-Regular;
          font-weight: 500;
          color: #0F0629;
        }
        .fees,.total,.commission-input {
          display: flex;
          flex-direction: column;
          > span {
            margin-bottom: 6px;
            font-weight: 500;
            color: var(--dark-2);
            line-height: 19px;
          }
        }
        .commission-input {
          position: relative;
          .defalut-text {
            position: absolute;
            font-family: Sora-Regular;
            color: var(--dark-4);
            line-height: 20px;
            left: 15px;
            top: 34px;
            margin: 0;
          }
          .haifenhao {
            position: absolute;
            font-weight: 500;
            font-size: var(--font-size-large);
            color: var(--dark-1);
            right: 30px;
            top: 34px;
            margin: 0;
          }
          :deep(.ant-input-number) {
            width: 200px;
            .ant-input-number-handler-wrap {
              opacity: 1;
            }
          }
        }
        .total {
          > button {
            width: 96px;
            display: flex;
            justify-content: space-between;
            > span {
              align-self: center;
            }
          }
        }
        .fees {
          > button {
            width: 144px;
          }
        }
        .fees,.total {
          > button {
            padding-left: 12px;
            text-align: left;
            border-radius: 8px;
            font-weight: 500;
            font-size: var(--font-size-large);
            color: var(--dark-1);
          }
        }
      }
      
    }
  }
  </style>