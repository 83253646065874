<template>
  <div class="product">
    <div class="breadcrumbs">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-shangpin"></use>
        </svg>
        <!-- <span class="shangpin"><shangpin/></span> -->
      </breadcrumbs>
    </div>
    <a-spin :spinning="spinning">
      <div class="content-wrap">
        <div class="warning" v-if="deatilData.status === 'inactive'">
          <span>
            <svg class="icon" aria-hidden="true" style="fill: #F59B00">
              <use xlink:href="#icon-a-warring-tianchong3"></use>
            </svg>
            <span>Product it not active</span>
          </span>
          <a-button class="button-yellow" @click="() => onChangeStatus('active')">Activate Product</a-button>
        </div>
        <div class="content">
          <div class="content-top">
            <div class="left">
              <img :src="deatilData.imageUrl" v-if="deatilData.imageUrl"/>
            </div>
            <div class="right">
              <div class="detail" ref="detailRef">
                <div class="title">
                  <div class="title-head">
                    <p>Product Details</p>
                    <div class="operation" v-if="deatilData.status === 'active'">
                      <artmisads-select-button size="small">...</artmisads-select-button>
                      <div class="inactive-btn">
                        <span class="deactivate" @click="() => setShowDeactivateModal(true)">Deactivate Products</span>
                        <artmisads-modal
                          v-model:open="showDeactivateModal"
                          :title="`Are you sure you want to Deactivate this Product?`"
                          :width="500"
                          :destroyOnClose="true"
                          :maskClosable="false"
                        >
                          <div class="inactive-content">
                            <p>Deactivating a Product will archive all active Publisher/Creator links. and make this product unavailable to publishers and creators.</p>
                          </div>
                          <template #footer>
                            <artmisads-button @click="() => setShowDeactivateModal(false)">Cancel</artmisads-button>
                            <artmisads-button @click="() => onChangeStatus('inactive')" type="primary">Confirm</artmisads-button>
                          </template>
                        </artmisads-modal>
                      </div>
                    </div>
                  </div>
                  <p><a :href="deatilData.productUrl" target="_blank">{{deatilData.title}}</a></p>
                </div>
                <div class="items">
                  <div class="item">
                    <span>ASIN:</span>
                    <span>{{deatilData.asin}}</span>
                  </div>
                  <div class="item">
                    <span>Brand:</span>
                    <span><a :href="`/#/admin/brand/detail/${deatilData.brandId}`">{{deatilData.brandName}}</a></span>
                  </div>
                  <div class="item">
                    <span>Price:</span>
                    <span>${{Number(deatilData.price || 0).toFixed(2)}}</span>
                  </div>
                  <div class="item">
                    <span>Category:</span>
                    <span>{{deatilData.category}}</span>
                  </div>
                  <div class="item">
                    <span>Best Seller Rank:</span>
                    <span>{{deatilData.bestSellerRank}}</span>
                  </div>
                  <div class="item">
                    <span>Availiability:</span>
                    <span>{{availiabilityMap[deatilData.availiability] || 'Out Of Stock'}}</span>
                  </div>
                  <div class="item">
                    <span>Commission:</span>
                    <span class="edit-commission">
                      <artmisads-button :isSquare="true" type="primary" size="middle" class="commission" @click="() => setShowSetCommission(true)">
                        <span class="text">{{deatilData.commission?.toFixed(1)}}%</span><EditOutlined />
                      </artmisads-button>
                      <a-button class="history" @click="() => setShowCommissionHistory(true)"><HistoryOutlined /></a-button>
                    </span>
                  </div>
                  <div class="item">
                    <span>Status:</span>
                    <span style="opacity: 1;"><showStatus :isActive="deatilData.status === 'active'"/></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" class="content-bottom">
            <div class="creators">
              <p>Creators</p>
              <div class="content-bottom-wrap">
                <div class="content">
                  <svg class="icon" aria-hidden="true" style="fill: rgba(15, 6, 41, .5);">
                    <use xlink:href="#icon-kongshuju"></use>
                  </svg>
                  <p>No Creators are promoting this product</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <setSingleProductCommission
        :showSetCommission="showSetCommission"
        :setShowSetCommission="setShowSetCommission"
        :getProductDetail="getProductDetail"
        :deatilData="deatilData"
        :productId="productId"
        v-if="deatilData.id"
      />
      <commissionHistory
        :setShowCommissionHistory="setShowCommissionHistory"
        :showCommissionHistory="showCommissionHistory"
        :productId="productId"
        v-if="deatilData.id"
      />
      <contextHolder />
    </a-spin>
  </div>
</template>
<script lang="jsx" setup>
  import { computed, onMounted, ref, watch } from 'vue';
  import { HistoryOutlined,EditOutlined } from '@ant-design/icons-vue';
  import showStatus from '../../Brands/components/showStatus.vue';

  import { productsApi } from '../../../../server/index'
  import breadcrumbs from '../../breadcrumbs.vue';
  import setSingleProductCommission from './setSingleProductCommission.vue';
  import commissionHistory from './commissionHistory.vue';
  import { message, notification } from 'ant-design-vue';
  import { useRoute } from 'vue-router';

  const availiabilityMap = {
    IN_STOCK: 'In Stock',
    OUT_OF_STOCK: 'Out Of Stock'
  }

  const route = useRoute();
  const showCommissionHistory = ref(false);
  const showDeactivateModal = ref(false);
  const showSetCommission = ref(false);
  const detailRef = ref(null);
  const spinning = ref(true);
  const productId = ref('');
  const deatilData = ref({});
  const breadcrumbsData = computed(() => {
    const data = {
      title: 'Your Products',
      des: 'Review you active ASINs that will be listed on our marketplace for Creator discovery',
      color:'#4682FA',
      links: [
        {
          name: 'Home',
          path: '/#/admin/welcome'
        },
        {
          name: 'Products',
          path: '/#/admin/product'
        },
        {
          name: 'Product Detail',
          path: `/#/admin/product/${productId.value}`
        },
      ]
    }
    return data;
  })

  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Notice`,
      description: description,
      placement: 'topRight',
      duration: 3
    });
  };

  const setShowDeactivateModal = status => {
    showDeactivateModal.value = status;
  }

  const setShowCommissionHistory = status => {
    showCommissionHistory.value = status;
  }
  const setShowSetCommission = status => {
    showSetCommission.value = status;
  }

  const onChangeStatus = async status => {
    spinning.value = true;
    let res, params = [productId.value];
    if (status === 'active') {
      res = await productsApi.activeProduct(params);
    } else {
      res = await productsApi.inactiveProduct(params);
    }
    const { success, error, code } = res;
    if (success) {
      if (status === 'active') {
        message.success('Active');
      } else {
        message.success('Inactive');
      }
      deatilData.value.status = status;
    } else {
      if (code === '1014') {
        openNotification(error);
      }
    }
    spinning.value = false;
    showDeactivateModal.value = false;
  }

  const getProductDetail = async id => {
    spinning.value = true;
    const res = await productsApi.getProductDetail(id);
    const { success, data } = res;
    if (success) {
      deatilData.value = data;
    }
    spinning.value = false;
  }

  onMounted(() => {
    const { id } = route.params;
    if (id) {
      productId.value = id;
      getProductDetail(id);
    }
  })

</script>
<style lang="less" scoped>
  .product{
    padding: 0 16px 16px;
  }
 
  .content {
    display: flex;
    flex-direction: column;
  }
  .warning {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding: 11px 6px 11px 13px;
    background: #FEF4E5;
    border: 2px solid #F6AC2D;
    border-radius: 12px;
    font-family: Sora-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #0F0629;
    letter-spacing: 0.4px;
    line-height: 22px;
    > span {
      display: flex;
      svg {
        width: 26px;
        height: 26px;
        margin-right: 5px;
      }
      > span {
        font-family: Sora-Regular;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 26px;
      }
    }
  }
  .content-top {
    display: flex;
    background: #FFFFFF;
    box-shadow: var(--section-box-shadow-2);
    border-radius: 12px;
    padding: 16px;
  }
  .left {
    width: 256px;
    height: 256px;
    border-radius: 8px;
    > img {
      width: 256px;
      height: 256px;
      border-radius: 8px;
      object-fit: contain;
    }
  }
  .right {
    flex: 1;
    margin-left: 24px;
    .detail {
      :deep(.ant-popover) {
        width: 400px;
        .popconfirm-title {
          > p {
              font-family: Sora-Regular;
              font-weight: 400;
              font-size: 14px;
              color: #0F0629;
              letter-spacing: 0.4px;
              line-height: 22px;
            &:first-child {
              font-weight: 500;
              margin-bottom: 6px;
            }
          }
        }
        .ant-popover-inner {
          padding: 15px;
        }
      }
      .title {
        .title-head {
          display: flex;
          justify-content: space-between;
          > p {
            font-weight: 500;
            font-size: 20px;
            color: #0F0629;
            letter-spacing: 0.5px;
            line-height: 28px;
          }
          .operation {
            position: relative;
            align-self: center;
            > span {
              cursor: pointer;
              padding: 0 12px;
              background: #FFFFFF;
              border: 1px solid #CCC7D9;
              box-shadow: 0 1px 0 0 #0f06290d;
              border-radius: 8px;
              margin-left: 10px;
            }
            .inactive-btn {
              position: absolute;
              right: 0;
              display: none;
              // display: flex;
              padding-top: 10px;
              .deactivate {
                width: 180px;
                text-align: center;
                cursor: pointer;
                padding: 4px 12px;
                background: #FFFFFF;
                border: 1px solid #CCC7D9;
                box-shadow: 0 1px 0 0 #0f06290d;
                border-radius: 8px;
                margin-left: 10px;
              }
            }
            &:hover {
              .inactive-btn {
                display: flex;
              }
            }
          }
        }
        > p {
          font-family: Sora-Regular;
          font-weight: 400;
          font-size: 13px;
          color: #0F0629;
          letter-spacing: 0.3px;
          line-height: 18px;
          margin-top: 6px;
          text-decoration: underline;
          > a {
            color: #0F0629;
          }
        }
      }
      .items {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        position: relative;
        &::after {
          left: 50%;
          top: 10px;
          content: '';
          position: absolute;
          width: 0;
          height: 143px;
          border-right: 1px solid #E8E5ED;
        }
        .item {
          padding-left: 10px;
          padding-right: 10px;
          width: 50%;
          display: flex;
          justify-content: space-between;
          &:nth-child(2n) {
            padding-left: 50px;
          }
          &:nth-child(2n+1) {
            padding-right: 50px;
          }
          > span {
            line-height: 40px;
            opacity: 0.5;
            font-size: 14px;
            color: #0F0629;
            letter-spacing: 0.4px;
            align-self: center;
            &:last-child {
              opacity: 0.9;
            }
            > a {
            color: #0F0629;
            text-decoration: underline;
              }
          }
        }
        .edit-commission {
          display: flex;
          > button {
            margin-left: 10px;
          }
          .commission {
            align-self: center;
            .text {
              padding-right: 12px;
              font-weight: 600;
              position: relative;
              &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 2px;
                width: 0;
                height: 18px;
                border-right: 1px solid var(--border-5);
              }
            }
            > span {
              &:last-child {
                margin-left: 10px;
              }
            }
          }
          .history {
            border: none;
            padding: 0;
            line-height: 32px;
            display: flex;
            > span {
              align-self: center;
            }
            :deep(svg) {
              width: 20px;
              height: 20px;
              fill: var(--dark-3);
            }
          }
        }
      }
    }
  }
  .content-bottom {
    .creators {
      margin-top: 20px;
      background: #FFFFFF;
      border: 2px solid #E8E5ED;
      border-radius: 12px;
      .content-bottom-wrap {
        padding: 16px;
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 85px 0 85px 0;
        background: #F8F7FC;
        border-radius: 8px;
        text-align: center;
        > svg {
          width: 96px;
          height: 96px;
          align-self: center;
        }
        > p {
          margin-top: 12px;
          opacity: 0.7;
          font-family: Sora-Medium;
          font-weight: 500;
          font-size: 18px;
          color: #0F0629;
          letter-spacing: 0.4px;
          line-height: 25px;
        }
      }
      > p {
        padding: 12px 16px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #0F0629;
        letter-spacing: 0.5px;
        line-height: 28px;
        border-bottom: 1px solid #E8E5ED;;
      }
      
    }
  }
</style>